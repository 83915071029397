<template>
  <tr :style="{ background: props.item.visibility_id == 2 ? '#f1f1f1': 'white' }">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <v-icon :color="postCategory.category.color" large>
        {{ postCategory.category.icon }}
      </v-icon>
    </td>
    <td class="justify-left">
      <strong>
        {{ postCategory.category.name }}
      </strong>
    </td>
    <td class="justify-left">
      <strong>
        /{{ postCategory.category.slug }}
      </strong>
    </td>

  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Posts',
    computed: {
      postCategory () {
        return this.props.item
      }
    },
    methods: {
      listPostImages: function () {
        this.$WiListDialog({
          wiConfig: 'post-file',
          filter: {
            post_id: this.props.item.id
          },
          wiEditData: {
            post_id: this.props.item.id
          }
        })
      },
      listPostCategories: function () {
        this.$WiListDialog({
          wiConfig: 'post-category',
          filter: {
            post_id: this.props.item.id
          },
          wiEditData: {
            post_id: this.props.item.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>